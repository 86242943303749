import { graphql } from "gatsby"
import { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import Layout from "../../components/layout"
import React from "react"
import styles from "./styles.module.scss"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import FoldedContent from "../../components/text-helpers/folded-content"
import SEO from "../../components/seo"
import { Tab, Tabs, Typography } from "@material-ui/core"
import t from '../../services/GetTranslationValue'
import url from '../../services/Router'
import MainSearch from "../../components/main-search/main-search"
import NullIf from "../../services/NullIf"
import RichText from "../../components/text-helpers/rich-text"
import TableOfContent from "../../components/table-of-content/table-of-content"
import ShowMoreText from "react-show-more-text"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import BlockContent from "../../components/text-helpers/portable-text"

const DateSection = ({title, casinos, locale}) => {
  return <div className={`${styles.newCasinosContainer}`}>
    <a name={`casinos_${url.removeAccents(title)}`} id={`casinos_${url.removeAccents(title)}`}>
    <h2 > {title} </h2>
    </a>
    <div className="cards-injected">
      <MainSearch bonuses={casinos} locale={locale} />
    </div>
  </div>
}

const Content = ({ currentText, casinos, locale, dates, translations }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  function footerExists() {
    if (currentText._rawFooterText.some(x => x.style === "h2" || x.style === "h3" || x.style === "h4")) {
      return (
        <div className={`${styles.tocParent}`}>
          <TableOfContent pageContext={currentText._rawFooterText} />
        </div>
      )
    }
  }

  return (
    <div className={`${styles.newCasinos}`}>

        {currentText._rawFooterText ? footerExists() : ""}

      <div className={`${styles.mainTitle}`}>
        <h1 className="contentWrapperTitle">{currentText.h1Tag}</h1>
        <ShowMoreText
          keepNewLines={false}
          anchorClass=''
          more={t.get(translations, 'show-more')}
          less={t.get(translations, 'show-less')}>
          { currentText._rawCombinedBody && <BlockContent blocks={currentText._rawCombinedBody} /> }
        </ShowMoreText>
      </div>

      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`new-casinos-tabs ${styles.tabs}`}
      >
        {dates.map((x, index) => (
          <Tab
            label={x}
            {...TabsIndex(index)}
            className={`${styles.tabTitle}`}
          />
        ))}

      </Tabs>
      <div className={`${styles.tabContent}`} >
        <div className={`${styles.tabBody}`}>
          <DateSection locale={locale}
            casinos={casinos.filter(x => NullIf.isNotNullOrEmpty(x))
                            .filter(x => x.dateEstablished === dates[value])}
            title={t.get(translations, 'casinos-in-year') + ' ' + dates[value]}
          />
        </div>
      </div>

      {
        currentText._rawFooterText &&
        <div>
          <BlockContent blocks={currentText._rawFooterText} />
        </div>
      }
    </div>
  )
}

const NewCasinosPage = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)

  const { locale, alternateLocaleSlugs } = pageContext
  let { currentText, dates } = data
  let [currentTextNode] = currentText.edges

  let remainingDates = dates.distinct.slice().reverse();

  return (
    <Layout breadcrumbNameOverride={currentTextNode &&
    currentTextNode.node &&
    currentTextNode.node.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoTitle
        }
        description={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoMeta
        }
         bonuses={bonuses}
      />

      {/* {currentTextNode &&
        currentTextNode.node &&
        currentTextNode.node.heroImage && (
          <Img fluid={currentTextNode.node.heroImage.asset.fluid} />
        )} */}

      {currentText &&
        currentTextNode &&
        currentTextNode.node &&(
          <Content
            currentText={currentTextNode.node}
            translations={translations}
            locale={locale}
            casinos={bonuses}
            dates={remainingDates}
          />
        )}
    </Layout>
  )
}

export default localize(NewCasinosPage)
export const pageQuery = graphql`
  query NewCasinosQuery {
    dates: allSanityCasino {
      distinct(field: dateEstablished)
    }
    currentText: allSanityGenericLandingPage(
      filter: { name: { eq: "New Casinos" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }
  }
`
